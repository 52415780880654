import Footer from "../components/Footer";
// import HeroPages from "../components/HeroPages";
// import PlanTrip from "../components/PlanTrip";
// import AboutMain from "../images/about/about-main.jpg";
// import Box1 from "../images/about/icon1.png";
// import Box2 from "../images/about/icon2.png";
// import Box3 from "../images/about/icon3.png";

function About() {
  return (
    <>
      <section className="about-page">
        {/* <HeroPages name="About" /> */}
        <br></br>
        <div className="container">
          <div className="about-main">
            {/* <img
              className="about-main__img"
              src={AboutMain}
              alt="car-renting"
            /> */}
            <div className="about-main__text">
              {/* <h3>Бидний тухай</h3> */}
              <h2>Бидний тухай</h2>
              <p>
                Манай компани нь Мэдээллийн технологи, гадаад худалдаа, тээврийн чиглэлээр үйл ажиллагаа явуулдаг бөгөөд 2017 онд үүсгэн байгуулагдсан. 
                <br/><br/>Мөн манай байгууллага нь Google компаний албан ёсны хамтрагч байгууллагаар ажиллаж байна. <br></br><br></br>Та манай байгууллагаар дамжуулан Google компаний google workspace үйлчилгээг авч, зөвлөгөө болон тусламж үйлчилгээг цаг алдалгүй монгол хэлээрээ авах боломжтой.
                </p>
              {/* <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  <img src={Box1} alt="car-icon" />
                  <span>
                    <h4>20</h4>
                    <p>Car Types</p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box2} alt="car-icon" />
                  <span>
                    <h4>85</h4>
                    <p>Rental Outlets</p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box3} alt="car-icon" className="last-fk" />
                  <span>
                    <h4>75</h4>
                    <p>Repair Shop</p>
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          {/* <PlanTrip /> */}
        </div>
      </section>
      {/* <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
          <h2>Google компаний албан ёсны хамтрагч байгууллага "Клауд Машин" ХХК</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3>(976) 72445559</h3>
            </span>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default About;
