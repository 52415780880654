import { FaEnvelope } from "react-icons/fa";
import { FaPhoneFlip } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
// import { FaLocationDot } from "react-icons/fa6";


function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li style={{ paddingBottom: "20px" }}>
                <span>Бидэнтэй холбогдох</span>
              </li>
              <li style={{ paddingBottom: "20px" }}>
                Бүтээгдэхүүн үйлчилгээтэй холбоотой тодруулга, зөвлөгөө
                мэдээлэл, үнийн санал авахыг хүсвэл дараахь сувгуудаар
                холбогдоно уу.
              </li>
              <li>
                <FaPhoneFlip style={{ marginRight: "20px" }} />
                <a href="callto: +97672445559"> +976 72445559</a>
              </li>
              <li>
                <FaEnvelope style={{ marginRight: "20px" }} />
                <a
                  href="mailto: 
                  info@cloudmachine.mn"
                >
                  &nbsp; info@cloudmachine.mn
                </a>
              </li>
              <li>
                <FaFacebook style={{ marginRight: "20px" }} />
                <a href="https://www.facebook.com/CloudMachine.mn">
                  &nbsp; https://facebook.com/cloudmachine.mn
                </a>
              </li>

              <li>
                <TbWorldWww style={{ marginRight: "20px" }} />
                <a href="https://cloudmachine.mn">
                  &nbsp; https://cloudmachine.mn
                </a>
              </li>
              {/* <li>
                <FaLocationDot style={{ marginRight: "20px" }} />
                <a href="https://maps.app.goo.gl/CSqyi2NmVYDCHm9L6">
                  &nbsp; Хануул дүүрэг, 24-р хороо, Наадамчдын гудамж, Food city
                mall, Улаанбаатар, Монгол улс
                </a>
              </li> */}

              {/* <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://jephunneh.com/"
                >
                  Design by cjephuneh
                </a>
              </li> */}
            </ul>

            {/* <ul className="footer-content__2">
              <li>Company</li>
              <li>
                <a href="#home">New York</a>
              </li>
              <li>
                <a href="#home">Careers</a>
              </li>
              <li>
                <a href="#home">Mobile</a>
              </li>
              <li>
                <a href="#home">Blog</a>
              </li>
              <li>
                <a href="#home">How we work</a>
              </li>
            </ul> */}

            {/* <ul className="footer-content__1">
            <li>
                <span>Цагийн хуваарь</span>
              </li>
              <li>
                Бүтээгдэхүүн үйлчилгээтэй холбоотой зөвлөгөө болон тодруулга мэдээлэл авахыг хүсвэл дараахь сувгуудаар холбогдоно уу.
              </li>
              <li>
                <p>
                  <i className="fa-solid fa-phone"></i> &nbsp; Утас: (976) -72445559
                </p>
              </li>

              <li>
                <a
                  href="mailto: 
                  info@cloudmachine.mn"
                >
                  <i className="fa-solid fa-envelope"></i>
                  &nbsp; Мэйл: info@cloudmachine.mn
                </a>
              </li>
            </ul> */}

            {/* <ul className="footer-content__2">
              <li>Subscription</li>
              <li>
                <p>Subscribe your Email address for latest news & updates.</p>
              </li>
              <li>
                <input type="email" placeholder="Enter Email Address"></input>
              </li>
              <li>
                <button className="submit-email">Submit</button>
              </li>
            </ul> */}
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
