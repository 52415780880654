// import Img2 from "../images/testimonials/pfp1.jpg";
// import Img3 from "../images/testimonials/pfp2.jpg";

function Testimonials() {
  return (
    <>
      <section className="testimonials-section">
        <div className="container">
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              {/* <h4>Бүтээгдэхүүн үйлчилгээ</h4> */}
              <br></br><br></br>
              <h2>Google workspace үйлчилгээ</h2>
            </div>
            <p style={{ fontSize: "16px", fontWeight: "normal" }}>
                Google workspace үйлчилгээ гэдэг нь үүлэн технологид суурилсан, аюулгүй байдлыг сайтар хангасан, байнгын ажиллагаатай үйлчилгээ бөгөөд дотроо дараахь багц програмуудыг агуулдаг.
                <br></br><br></br>
                <li>Gmail - Өөрийн домэйн нэр бүхий мэйлийн үйлчилгээ бөгөөд үүлэн технологид суурилсан тул хаанаас ч хандах боломжтой. Хэмжээний хувьд google drive-тай нийлээд 30GB</li>
                <li>Google drive - Үүлэн технологид суурилсан онлайн хадгалах үйлчилгээ бөгөөд байгууллагын бүхий л мэдээллийг хадгалж, хуваалцах боломжтой. </li>
                <li>Google contact - Байгууллагын контактуудын төвлөрсөн бүртгэл.</li>
                <li>Calendar - Хурал уулзалт, ажлуудаа бүртгэн </li>
                <li>Google meet - Онлайн хурлын үйлчилгээ бөгөөд нэг дор 100 хүртэл хүнтэй видео хурал хийх боломжтой. Оролцогчдын тоо нь багцаас хамаарч 500 хүртэл нэмэгдэж болно </li>
                <li>Google docs & Sheets - Үүлэн технологид суурилсан оффисын програм бөгөөд хялбар байдлаар хуваалцаж удирдах боломжийг олгоно </li>
                <br></br><br></br>
                <li>Google workspace үйлчилгээний багцын мэдээллийг <a href="https://support.google.com/a/answer/6043385?hl=en&fl=1&sjid=6862888262425624334-NC"> энэ </a> холбоосоор авна уу. </li>
              </p>
            {/* <div className="all-testimonials">
              <div className="all-testimonials__box">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-left"></i>
                </span>
                <p>
                  "We rented a car from this website and had an amazing
                  experience! The booking was easy and the rental rates were
                  very affordable. "
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img2} alt="user_img" />
                    <span>
                      <h4>Jones Mbogholi</h4>
                      <p>Kenya</p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="all-testimonials__box box-2">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                  "The car was in great condition and made our trip even better.
                  Highly recommend for this car rental website!"
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img3} alt="user_img" />
                    <span>
                      <h4>Solomon Odingo </h4>
                      <p>South Sudan</p>
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
