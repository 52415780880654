import Footer from "../components/Footer";
// import HeroPages from "../components/HeroPages";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneFlip } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
// import { FaLocationDot } from "react-icons/fa6";

function Contact() {
  return (
    <>
      <section className="contact-page">
        {/* <HeroPages name="Contact" /> */}
        <div className="container">
          <div className="contact-div">
            <div className="contact-div__text">
              <h2>Холбоо барих</h2>
              <p>
                Манай бүтээгдэхүүн үйлчилгээтэй холбоотой тодруулга, үнийн мэдээлэл болон
                тусламж зөвлөгөө шаардлагатай бол дараахь сувгуудаар бидэнтэй
                холбогдоно уу.
              </p>
              <li>
                <FaPhoneFlip style={{ marginRight: "20px" }} />
                <a href="callto: +97672445559"> +976 72445559</a>
              </li>
              <li>
                <FaEnvelope style={{ marginRight: "20px" }} />
                <a
                  href="mailto: 
                  info@cloudmachine.mn"
                >
                  &nbsp; info@cloudmachine.mn
                </a>
              </li>
              <li>
                <FaFacebook style={{ marginRight: "20px" }} />
                <a href="https://www.facebook.com/CloudMachine.mn">
                  &nbsp; https://facebook.com/cloudmachine.mn
                </a>
              </li>
              <li>
                <TbWorldWww style={{ marginRight: "20px" }} />
                <a href="https://cloudmachine.mn">
                  &nbsp; https://cloudmachine.mn
                </a>
              </li>
              {/* <li>
                <FaLocationDot style={{ marginRight: "20px" }} />
                <a href="https://maps.app.goo.gl/CSqyi2NmVYDCHm9L6">
                  &nbsp; Хануул дүүрэг, 24-р хороо, Наадамчдын гудамж, Food city
                mall, Улаанбаатар, Монгол улс
                </a>
              </li> */}
{/* 
              <p>
                Хаяг: Хануул дүүрэг, 24-р хороо, Наадамчдын гудамж, Food city
                mall, Улаанбаатар, Монгол улс
              </p> */}
            </div>
            {/* <div className="contact-div__form">
              <form>
                <label>
                  Full Name <b>*</b>
                </label>
                <input type="text" placeholder='E.g: "Joe Shmoe"'></input>

                <label>
                  Email <b>*</b>
                </label>
                <input type="email" placeholder="youremail@example.com"></input>

                <label>
                  Tell us about it <b>*</b>
                </label>
                <textarea placeholder="Write Here.."></textarea>

                <button type="submit">
                  <i className="fa-solid fa-envelope-open-text"></i>&nbsp; Send
                  Message
                </button>
              </form>
            </div> */}
          </div>
        </div>
        {/* <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
            <h2>Google компаний албан ёсны хамтрагч байгууллага "Клауд Машин" ХХК</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>(123) 456-7869</h3>
              </span>
            </div>
          </div>
        </div> */}
        <Footer />
      </section>
    </>
  );
}

export default Contact;
